@use '../config/' as *;
@function power($base, $exponent) {
    $result: 1;
    @for $_ from 1 through $exponent {
        $result: $result * $base;
    }
    @return $result;
}
.waf-fixtures {
    --card-height: 12rem;
    --space-line: 2rem;
    padding: 0;
    margin: 0 var(--half-space-negative);
    @extend %relative;
    .waf-head {
        @extend %d-none;
    }
    .swiper {
        position: unset;
        @extend %p-l-half;
        @extend %p-r-two-space;
        &:first-child {
            height: 5rem;
            position: relative;
            @extend %flex-n-c;
            .tabs-item:not(:last-child) {
                &::after {
                    content: "";
                    width: .1rem;
                    height: 60%;
                    right: var(--full-space-negative);
                    @extend %position-v-center;
                    @extend %c-black-bg-10;
                }
            }
        }
        &:nth-child(2) {
            @extend %m-t-two-space;
        }
        &-button {
            width: 5rem;
            height: 5rem;
            top: 0rem;
            &-prev {
                left: 0;
                background-image: linear-gradient(to left, transparent 0%, var(--c-white) 35%);
            }
            &-next {
                right: 0;
                background-image: linear-gradient(to right, transparent 0%, var(--c-white) 35%);
            }
            &-disabled {
                opacity: 0;
            }
        }
        &-pagination {
            @extend %d-none;
        }
    }
    .tabs-section {
        border: .1rem solid hsl(var(--hsl-c-light-grey-1)/ 1);
        .tabs-item {
            @extend %p-y-half;
            @extend %text-center;
        }
        .card-title {
            @extend %font-14-pr;
            @extend %c-blue-10;
            @extend %uppercase;
        }
        .swiper-slide-active {
            .card-title {
                @extend %c-orange-10;
                font-family: $font-pb;
            }
        }
    }
    .tabs-container-section {
        .swiper-button,
        .swiper-pagination {
            @extend %d-none;
        }
        .swiper-slide {
            height: auto;
            justify-content: space-around;
            @extend %flex-column;
            &-next {
                .card-item {
                    --card-height: calc(12rem * 2)
                }
            }
            &:not(:last-child) {
                .card {
                    &-wrap {
                        &::before {
                            content: "";
                            width: 0.7rem;
                            height: 50%;
                            z-index: var(--zindex-pattern);
                            border: .1rem solid hsl(var(--hsl-c-black)/ 0.3);
                            border-radius: .3rem;
                            @include position-combo(y-center, -0.7rem);
                        }
                        &::after {
                            content: '';
                            width: 2.5rem;
                            height: 3.5rem;
                            background: url('/static-assets/images/svg/line.svg?v=#{$image-version}') top left;
                            background-size: cover;
                            @include position(17%,-2.6rem,null,null);
                        }
                    }
                    &-item {
                        &:nth-child(even) {
                            .card-wrap {
                                &::after {
                                    transform: scaleY(-1);
                                    top: 44%
                                }
                            }
                        }
                    }
                }
            }
            &:not(:first-child) {
                .card-item {
                    .top-line,
                    .bottom-line {
                        content: '';
                        position: absolute;
                        width: 1rem;
                        left: -0.9rem;
                        height: calc(var(--card-height) / 4 - 2.5rem);
                        border-left: .1rem solid var(--c-orange);
                    }
                    .top-line {
                        bottom: calc(50% + var(--space-line));
                        border-bottom: .1rem solid var(--c-orange);
                        border-bottom-left-radius: .3rem;
                    }
                    .bottom-line {
                        content: '';
                        border-top: .1rem solid var(--c-orange);
                        border-top-left-radius: .3rem;
                        top: calc(50% + var(--space-line));
                    }
                }
            }
        }
        .match-no {
            @extend %font-10-pr;
            @extend %c-orange-10;
        }
        .card {
            &-item {
                position: relative;
                transition: height .3s;
                height: var(--card-height);
                @extend %flex-column-c-n;
                @extend %p-y-one-n-half;
            }
            &-wrap {
                border: .1rem solid hsl(var(--hsl-c-black)/0.2);
                box-shadow: 0rem .4rem 1.6rem hsl(var(--hsl-c-black)/ 0.1);
                @extend %c-white-bg-10;
                @extend %relative;
                @extend %half-radius;
                @extend %p-half;
            }
        }
        .team {
            @extend %font-12;
            @extend %m-t-half;
            @extend %c-blue-10;
            @extend %flex-n-c;
            &-a {
                margin-top: 0;
            }
            &-logo {
                width: 1.5rem;
                height: 1.5rem;
                object-fit: cover;
                @extend %circle-radius;
            }
            &-image {
                @extend %flex-c-c;
                @extend %m-x-half;
            }
            &-name {
                @extend %relative;
                @extend %m-zero;
                @extend %capitalize;
                @extend %font-12-pr;
                .name {
                    @extend %c-blue-10;
                    @extend %font-12-pr;
                }
            }
            &-rank {
                @extend %font-12-pr;
                @extend %c-blue-5;
            }
            &-score {
                @extend %c-blue-10;
                @extend %m-l-auto;
                @extend %font-14-pr;
            }
        }
        .won {
            .name,.team-score {
                font-family: $font-pb;
            }
        }
        .live {
            .match-no {
                @extend %relative;
                @extend %flex;
                &::after {
                    content: '';
                    width: 2.6rem;
                    height: 1.8rem;
                    right: 0;
                    background-image: url('/static-assets/images/svg/live-white.svg?v=#{$image-version}');
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: var(--full-radius);
                    @extend %c-error-bg-10;
                    @extend %position-v-center;
                }
            }
        }
        .injured,.disqualified,.forfeit {
            &::after {
                right: -4rem;
                border: .1rem solid var(--c-error);
                border-radius: var(--full-radius);
                @extend %p-x-half;
                @extend %c-error-10;
                @extend %position-v-center;
            }
        }
        .injured {
            &:after {
                content: 'INJ';
            }
        }
        .disqualified {
            &:after {
                content: 'DSQ';
                right: -4.8rem;
            }
        }
        .forfeit {
            &:after {
                content: 'FFT';
            }
        }
    }
    .content-wrapper-container {
        @extend %m-t-full;
    }
    .filter-section {
        @extend %p-x-full;
    }
    .filter-section {
        .filter-label-group {
            .title {
                @extend %c-blue-6;
            }
        }
    }
}
// Only for bracket download section once bracket development is done remove.
.download-section {
    @extend %p-x-full;
    .waf-head {
        @extend %m-b-full;
        @extend %flex;
    }
    .list-item {
        gap: var(--one-n-half-space);
        @extend %flex-column;
        @extend %p-one-n-half;
        @extend %half-radius;
        @extend %m-b-one-n-half;
        @include border(1, c-blue-2, 1);
        @include bg(c-light-grey-1, 2);
    }
    .action-title {
        @extend %font-20-psb;
        @extend %c-blue-10;
    }
    .btn-link {
        @extend %c-orange-10;
        @extend %font-16-pb;
        .text {
            @extend %font-16-pb;
        }
        &:after {
            content: '\e809';
            font-size: 2.2rem;
            line-height: 2rem;
            font-family: $font-icon;
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-fixtures {
        @include white-bg();
        .swiper {
            &:first-child {
                height: auto;
            }
        }
        .tabs-section {
            .card-title {
                @include font(16);
            }
        }
        .tabs-container-section {
            padding-inline: 0;
            .swiper-slide {
                .match-no {
                    @include font(12);
                }
                .card-item {
                    height: var(--card-height);
                }
                @for $height from 1 through 7 {
                    &:nth-child(#{$height + 1}) {
                        .card-item {
                            --card-height:#{12rem * power(2,$height)};
                        }
                    }
                }
            }
        }
        .swiper {
            &-button {
                width: 10rem;
                height: 4rem;
                &::after {
                    font-size: 3rem;
                }
            }
        }
        .filter-section {
            margin-top: var(--two-space);
            .filter {
                &-wrap {
                    position: relative;
                    .tabs {
                        width: 100%;
                    }
                }
                &-body {
                    position: unset;
                    align-items: flex-end;
                    flex-basis: 100%;
                    gap: var(--one-n-half-space) var(--full-space);
                }
                &-group {
                    flex-basis: unset;
                    gap: var(--full-space);
                    .waf-select-box {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
                &-label-group {
                    margin-block: 0;
                    flex-basis: unset;
                }
            }
            .tabs {
                margin-block: 0;
                .tab-name {
                    min-width: 6.4rem;
                    height: 3rem;
                    padding: 0;
                    @include flex-config(flex, null, center, center);
                }
            }
            .label-group-head {
                display: none;
            }
        }
    }
    // Only for bracket download section once bracket development is done remove
    .download-section {
        margin-top: 0;
        padding-top: var(--one-n-half-space);
        .action-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: var(--full-space);
        }
    }
    .events-bracket-page .body-wrap {
        min-height: unset;
    }
}