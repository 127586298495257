@use "../config/" as *;
.waf-fixtures {
    @extend %p-half;
    @extend %white-bg;
    .tabs-container {
        &-title {
            padding: var(--half-space) var(--full-space);
            margin-top: 0;
            @extend %c-blue-bg-10;
            @extend %c-white-10;
            @extend %font-14-pb;
            @include border-radius(var(--quarter-space));
        }
        &-group {
            margin-bottom: calc(var(--full-space) + var(--half-space));
        }
    }
    .content {
        &-item {
            position: relative;
            padding: var(--full-space) var(--half-space);
            border: 0.05rem solid hsl(var(--hsl-c-blue)/0.1);
            box-shadow: 0 0.4rem 1.6rem hsl(var(--hsl-c-black)/ 0.1);
            margin-bottom: calc(var(--quarter-space) + var(--half-space));
            @extend %c-white-bg-10;
            @include border-radius(var(--quarter-space));
        }
    }
    .card {
        &-content {
            @extend %flex-c-c;
        }
        &-item {
            position: relative;
            width: calc(50% - 2.5rem);
            @extend %flex-row-c-c;
        }
        &-img {
            position: relative;
            width: 4.2rem;
            height: 4.2rem;
            margin: 0 var(--quarter-space) var(--half-space);
            flex-shrink: 0;
            @include border-radius(50%, initial);
            @extend %ratio-1-1;
            @extend %c-blue-bg-6;
            img {
                border-radius: inherit;
            }
            > a {
                @extend %w-100;
                @extend %h-100;
                @extend %flex;
                border-radius: inherit;
            }
        }
        &-info {
            flex-direction: column-reverse;
            @extend %flex-n-c;
        }
        &-label {
            word-break: break-word;
            font: 1.4rem/1.36 $font-pr;
            @extend %capitalize;
            @extend %c-blue-10;
        }
        &-tag {
            font: 1.4rem/1.5 $font-pr;
            @extend %d-block;
            @extend %c-orange-10;
        }
        &-b {
            flex-direction: row-reverse;
            .card-info {
                margin-left: calc(var(--quarter-space) + var(--half-space));
                align-items: flex-start;
            }
            .card-img {
                .logo {
                    right: auto;
                    left: var(--half-space-negative);
                }
            }
        }
        &-a {
            .card-info {
                align-items: flex-end;
                margin-right: calc(var(--quarter-space) + var(--half-space));
            }
            .card-data {
                text-align: right;
            }
        }
        &-number {
            width: 3rem;
            @extend %m-b-two-space;
            @extend %text-center;
            @extend %font-20-pr;
            @extend %c-blue-10;
        }
        &-status {
            width: 8rem;
            @extend %flex-column;
            @extend %font-12-pr;
            .status {
                @extend %flex-c-c;
                @extend %c-blue-10;
            }
        }
        &-meta {
            @extend %flex-c-c;
            @extend %m-b-half;
            .status {
                position: absolute;
                right: var(--full-space);
                padding: 0.4rem 0.6rem;
                gap: var(--quarter-space);
                @extend %flex-n-c;
                @extend %c-error-bg-10;
                @extend %badges;
                .text {
                    font-size: 1rem;
                }
                &::before {
                    content: '';
                    width: 0.9rem;
                    height: 0.9rem;
                    aspect-ratio: 1/1;
                    flex-shrink: 0;
                    background: url('/static-assets/images/svg/live-white.svg?v=#{$image-version}') no-repeat center;
                }
                .text {
                    @extend %font-zero;
                    @extend %capitalize;
                }
            }
        }
        &-action {
            .text {
                @extend %font-zero;
            }
        }
    }
    .meta {
        @extend %font-14-pr;
        @extend %c-blue-6;
        &:not(:first-child) {
            &::before {
                content: '|';
            }
        }
        &:empty {
            @extend %d-none;
        }
    }
    .won {
        .card-label {
            font-family: $font-pb;
            @extend %c-orange-10;
        }
        .card-number {
            font-family: $font-pb;
        }
    }
    .logo {
        width: 2.4rem;
        height: 2.4rem;
        @extend %ratio-1-1;
        @extend %circle-radius;
        @include position(null, var(--half-space-negative), 0, null);
    }
    .vs {
        @extend %w-100;
        @extend %flex-c-c;
    }
    .waf-accordion-panel {
        .card-action {
            .text {
                font-size: 1rem;
                background: var(--c-orange);
                @extend %c-white-10;
                @extend %badges;
            }
            .btn-link {
                @extend %w-100;
                @extend %h-100;
                @include position-combo(inset);
                .text {
                    display: none;
                    bottom: var(--half-space);
                    @include position-combo(x-center);
                }
            }
        }
        // Remove for scroll
        .card-status {
            align-items: center;
            margin-bottom: var(--one-n-half-space);
            .vs {
                margin-right: .3rem;
                margin-left: .3rem;
                // position: absolute;
                // bottom: .5rem;
            }
        }
    }
    .waf-accordion-title {
        + .waf-accordion-panel {
            @extend %d-none;
        }
        .card-action {
            .btn-link {
                width: max-content;
                top: auto;
                bottom: var(--half-space);
                @include position-combo(x-center);
            }
        }
        &.active {
            .content-item {
                margin-bottom: 0;
                @include bg(c-blue, 1);
            }
            + .waf-accordion-panel {
                @extend %d-block;
            }
            + .waf-accordion-panel {
                .content-item {
                    margin-bottom: 0;
                }
            }
            .card-action {
                .btn-link {
                    &::after {
                        content: '\e802';
                    }
                }
            }
        }
        .card-action {
            width: 2.4rem;
            height: 2.4rem;
            position: relative;
            align-self: center;
            .btn-link {
                position: relative;
                @extend %w-100;
                @extend %h-100;
                @extend %flex-c-c;
                &::after {
                    position: inherit;
                    @include icon('\e806');
                    @include font(30);
                    @extend %c-blue-10;
                }
            }
        }
        .content-wrapper {
            @extend %flex-column;
        }
        .content-item {
            .vs {
                @extend %c-blue-10;
                @extend %font-14-pr;
                @extend %uppercase;
            }
            .card-number,
            .card-status {
                margin-bottom: 0;
            }
        }
    }
    .filter-section {
        padding-inline: 0;
        position: relative;
        .toggle-text {
            @extend %c-blue-2-8;
        }
    }
}
.waf-event-results {
    .waf-accordion-panel {
        .card {
            &-a .card-label {
                @extend %text-right
            }
            &-item {
                flex-basis: 100%;
                align-items: unset;
            }
            &-number {
                margin-block: 2.5rem 0;
            }
            &-content {
                align-items: flex-start;
            }
            &-status {
                min-width: max-content;
                margin-block: 2.4rem 0;
                .vs {
                    font-size: 3rem;
                    font-weight: 700;
                    line-height: 1;
                }
            }
            &-info {
                @extend %relative;
                .card-label {
                    &::after {
                        content: "";
                        z-index: var(--zindex1);
                        @include position-combo(inset);
                    }
                }
            }
        }
    }
    
}
@media (min-width:$desktop-min-width) {
    .waf-fixtures {
        padding: 0;
        .dependacy-layout-wrapper {
            margin-bottom: calc(var(--two-space) + var(--full-space));
        }
        .content-wrapper {
            height: 100%;
            @include flex-config(flex, row, space-between, center);
        }
        .content-item {
            height: 8.5rem;
            padding-block: var(--half-space);
        }
        .card {
            &-content {
                width: calc(100% - 40rem);
            }
            &-meta {
                width: 25rem;
                justify-content: flex-start;
                .status {
                    right: unset;
                    bottom: 2.5rem;
                    padding: 0.2rem 0.8rem;
                    .text {
                        line-height: var(--half-space);
                        @include font(12);
                    }
                }
            }
            &-info {
                width: calc(100% - 6.2rem);
                @include flex-config(flex, row, flex-end);
            }
            &-a {
                .card-info {
                    margin-right: var(--two-space);
                    align-items: center;
                }
                .card-img {
                    margin-left: var(--full-space);
                }
            }
            &-b {
                .card-info {
                    margin-left: var(--two-space);
                    flex-direction: row-reverse;
                    align-items: center;
                }
                .card-img {
                    margin-right: var(--full-space);
                }
            }
            &-img {
                margin: 0;
            }
            &-data {
                width: calc(100% - 4.2rem);
            }
            &-label {
                @include font(16);
            }
            &-number {
                @include font(24);
            }
            &-status {
                position: relative;
                width: auto;
                .status {
                    width: 5rem;
                    position: absolute;
                    left: 50%;
                    bottom: -2rem;
                    @include translate(-50%, 0);
                }
            }
            &-action {
                position: relative;
                width: 15rem;
                .btn-link {
                    position: unset;
                }
                .text {
                    font: 1.6rem/1.4 $font-pr;
                    @include color(c-blue);
                    &::after {
                        width: 2.4rem;
                        height: 2.4rem;
                        @include flex-config(flex, null, center, center);
                        @include icon('\e801');
                        @include border-radius(50%);
                        @include bg(c-light-grey-1, 10);
                        @include position(50%, 0);
                        @include font(24);
                        @include color(c-black);
                        @include translate(0, -50%);
                    }
                }
            }
        }
        .tabs-container-content {
            margin-bottom: var(--full-space);
        }
        .waf-accordion-title {
            .content-item {
                margin-bottom: 0;
                border-radius: unset;
                &.recent {
                    .card-number,
                    .card-status {
                        margin-bottom: 0;
                    }
                }
            }
            .card-meta {
                .status {
                    right: var(--three-space);
                }
            }
            .card-action {
                width: 15rem;
                .text {
                    font-size: 0;
                }
                .btn-link {
                    width: auto;
                    &::after {
                        content: unset;
                    }
                }
            }
            .content-wrapper {
                flex-direction: row;
            }
            + .waf-accordion-panel {
                display: none;
                .card-meta {
                    .status {
                        right: var(--three-space);
                    }
                }
                .card-img {
                    width: 3.2rem;
                    height: 3.2rem;
                }
            }
            .card-action {
                .text {
                    &::after {
                        content: '\e806';
                        background: transparent;
                    }
                }
            }
            &.active {
                .card-action {
                    .text {
                        &::after {
                            content: '\e802';
                        }
                    }
                    .btn-link {
                        &::after {
                            content: unset;
                        }
                    }
                }
            }
        }
        .waf-accordion-panel {
            .card-action {
                .text {
                    all: unset;
                    @include color(c-blue);
                }
                .btn-link {
                    position: initial;
                    .text {
                        position: unset;
                        display: inherit;
                    }
                }
            }
        }
        .filter-section {
            margin-top: var(--two-space);
            .filter-body {
                flex-basis: 100%;
            }
            .filter-group {
                flex-basis: 14%;
                .waf-select-box {
                    max-width: 15rem;
                }
            }
            .label {
                &-group-head {
                    display: none;
                }
            }
            .filter-label-group {
                flex-basis: 80%;
            }
            .filter-hide-data {
                top: 30%;
                width: 12%
            }
        }
        @include white-bg();
    }
    .waf-event-results {
        .filter-section {
            .filter {
                &-wrap {
                    position: relative;
                    .tabs {
                        width: 100%;
                    }
                }
                &-body {
                    position: unset;
                    align-items: flex-end;
                    flex-basis: calc(100% - var(--full-space) - 15rem);
                    gap: var(--one-n-half-space) var(--full-space);
                }
                &-group {
                    flex-basis: unset;
                    gap: var(--full-space);
                    .waf-select-box {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
                &-label-group {
                    margin-block: 0;
                    flex-basis: unset;
                }
            }
            .tabs {
                margin-block: 0;
                .tab-name {
                    min-width: 6.4rem;
                    height: 3rem;
                    padding: 0;
                    @include flex-config(flex, null, center, center);
                }
            }
            .label-group-head {
                display: none;
            }
        }
        .content-wrapper {
            justify-content: unset;
        }
        .waf-accordion-panel {
            .card-action {
                width: unset;
                position: unset;
                transform: unset;
                .btn-link {
                    position: absolute;
                    inset: 0;
                    .text {
                        gap: var(--full-space);
                        transform: unset;
                        @include flex-config(flex, null, null, center);
                        &::after {
                            right: var(--full-space);
                        }
                    }
                }
            }
            .card-number, .card-status {
                margin-top: 0;
            }
            .card-status {
                min-width: 4rem;
            }
            .card-item {
                justify-content: flex-end;
            }
        }
        .card {
            &-meta {
                max-width: 25rem;
                width: fit-content;
                margin-bottom: 0;
                @include position-combo("y-center", null, var(--half-space));
                .status {
                    position: unset;
                }
            }
            &-content {
                width: 100%;
                display: grid;
                grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
            }
            &-item {
                width: unset;
            }
        }
    }
}